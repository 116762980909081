import 'bootstrap';
import './bootstrap_custom.js'
import '../stylesheets/application'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require('popper.js')
require("packs/sb-admin-2")
require("packs/chat_rooms")
require("packs/ckeditor")
require("packs/users")
window.jQuery = $
window.$ = $

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../libs/', true, /\.js$/))
requireAll(require.context('../admins/', true, /\.js$/))
require("packs/wallet")
require("packs/chart_pie_dashboard")
