$(document).on('turbolinks:load', () => {
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
    $(".slider-center").slick('refresh');
    $(".slider-center-member").slick('refresh');
  });

  $(".slider-center").slick({
    dots: true,
    centerMode: true,
    slidesToShow: gon.archer_number_slide,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  }).on('afterChange click', function(event, slick, currentSlide){
    var id = $(this).find('.slick-current .monthly_pay').val();
    handleGatDataPayment(id)
  });

  $(".slider-center-member").slick({
    dots: true,
    centerMode: true,
    slidesToShow: gon.member_number_slide,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  }).on('afterChange click', function(event, slick, currentSlide){
    var id = $(this).find('.slick-current .monthly_pay').val();
    
    handleGatDataPayment(id)
  });
});

function addTransactionReceived(data) {
  let blockTransaction = `<div class="item-contact row">
    <h3>
      ${data.created_at}
    </h3>
    <img class="avatar rounded-circle" src="${data.member.member_profile.avatar}">
    <i class="fas fa-play"></i>
    <img class="avatar rounded-circle" src="${data.archer.archer_profile.avatar}">
    <div class="item">
      <p>${data.transaction_type}</p>
      <p>${data.amount} Y</p>
    </div>
  </div>`;
  $('#transaction-history').append(blockTransaction);
}

function handleGatDataPayment(id){
  if (id != "undefined" ) {
    setTimeout(function() {
      $.ajax({
      url:'/monthly_payment',
      type:"POST",
      data: {
        id: id
      },
      timeout: 3000,
      success: function(data){
      },error: function(xhr,response){
        console.log("Error code is "+xhr.status+" and the error is "+response);
      }
    })});
  }
}