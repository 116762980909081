// Set new default font family and font color to mimic Bootstrap's default styling
Chart.defaults.global.defaultFontFamily = 'Nunito', '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
Chart.defaults.global.defaultFontColor = '#858796';
$(document).on('turbolinks:load', () => {
  if (typeof gon != 'undefined' && typeof gon.members != 'undefined') {
    var ctx = document.getElementById("myPieChart");
    if(ctx){
      var myPieChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ["アーチャー", "メンバー", "アーチャーとメンバーの両方役割"],
          datasets: [{
            data: [gon.archers, gon.members, gon.both],
            backgroundColor: ['#4e73df', '#1cc88a', '#f6c23e'],
            hoverBackgroundColor: ['#2e59d9', '#17a673', '#dda20a'],
            hoverBorderColor: "rgba(234, 236, 244, 1)",
          }],
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#858796",
            borderColor: '#dddfeb',
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            caretPadding: 10,
          },
          legend: {
            display: false
          },
          cutoutPercentage: 80,
        },
      });
    }
  }
})