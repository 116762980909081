$(document).on('click', '.get_message', function() {
  var target = $(".msg_history")
  $('.chat_list').removeClass('active_chat');
  $(this).addClass('active_chat');
  $.ajax({
    url:'/api/v1/chat/' + $(this).data('item'),
    type:"GET",
    dataType: "json",
    timeout: 3000,
    success: function(data){
      $('.incoming_msg').remove();
      $('.outgoing_msg').remove();
      if (!data || data.data.length == 0) {
        return
      };
      if ($('.incoming_msg').length != 0) {
        return
      }
      var currentURL = window.location.href;
      var id = currentURL.substring(currentURL.lastIndexOf('/') + 1);
      data.data.forEach(function (item) { 
        var date = new Date(item.timestamp);
        let time = formatDate(date);
        var profilePicUrl = item.profilePicUrl;
        if (item.uid == id) {
          addMessageSended(item.text, time)
        } else {
          addMessagesReceived(item.text, time, profilePicUrl)
        }
      });
    },error: function(xhr,response){
      console.log("Error code is "+xhr.status+" and the error is "+response);
    }
  });
});

function addMessagesReceived(message, time, profilePicUrl) {
  let blockMessage = `<div class="incoming_msg">
    <div class="incoming_msg_img"> <img src=${profilePicUrl}
        alt="sunil"> </div>
    <div class="received_msg">
      <div class="received_withd_msg">
        <p>${message}</p>
        <span class="time_date">${time}</span>
      </div>
    </div>
    <br>
  </div>`;
  $('#chat_box').append(blockMessage);
}

function addMessageSended(message, time) {
  let blockMessage = `<div class="outgoing_msg">
    <div class="sent_msg">
      <p>${message}</p>
      <span class="time_date">${time}</span>
    </div>
    <br>
  </div>`;
  $('#chat_box').append(blockMessage);
}

function formatDate(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", 
                     "August", "September", "October", "November", "December"];
  return strTime + " | " + monthNames[date.getMonth()] + " "+ date.getDate() + " " + date.getFullYear();
}
